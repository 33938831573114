import React from "react"
import styled from "@emotion/styled"
import { RichText } from 'prismic-reactjs'

const Category = styled.section`
  margin: auto;
  max-width: 600px;
  margin-bottom: 40px;
  padding: 0 20px;
  background-color: transparent;
  @media (min-width: 1024px) {
    margin-bottom: 80px;
  }
`
const TitleWrap = styled.div` 
  padding-bottom: 0px;
  margin-bottom: 1rem;
  border-bottom: 1px solid #00ABD4;
`
const Title = styled.h2` 
  padding-bottom: 10px;
  margin-bottom: 2px;
  border-bottom: 1px solid #00ABD4;
`
const Item = styled.div` 
  display: flex;
  margin-bottom: 20px;
`
const Left = styled.div`
  flex:3;
  padding-right: 10px;
  max-width: 500px;
  }
  @media (min-width: 420px) {
    padding-right: 30px;
  }
  @media (min-width: 768px) {
    flex:4;
  }
  @media (min-width: 1024px) {
    flex:5;
  }
`
const Right = styled.div`
  padding-right: 10px;
  flex:1;
  display: flex;
  align-items: center;
  justify-content: right;
`
const Name = styled.div`
  font-family: "Oswald", sans-serif;
  color: #fff;
`
const Description = styled.div`
`
const Price = styled.div`
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  color: #fff;
`
const MenuItem = React.forwardRef((props, ref) => {
  const menu = props.menu
  let elements = []
  let name
  let items
  if(menu) {
    name = RichText.asText(menu.name)
    items = menu.items
    items.forEach((o) => {
      const e = o.relationship
      const name = e.name ? RichText.asText(e.name) : ''
      const description = e.description ? RichText.asText(e.description) : ''
      const price = e.price ? e.price : ''
      elements.push(<Item key={name}><Left><Name>{name}</Name><Description>{description}</Description></Left><Right><Price>{price}</Price></Right></Item>)
    })
  }
  return (
    <Category ref={ref}>
      <TitleWrap>
        <Title>{name}</Title>
      </TitleWrap>
      {elements}
    </Category>
  )
})

export default MenuItem
