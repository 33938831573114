import React from "react"
import styled from "@emotion/styled"
import { RichText } from "prismic-reactjs"

import img from "../images/fg-texture.jpg"

const Section = styled.section`
  min-height: 100vh;
  max-width: 100%;
  background-image: url(${img});
  display: flex;
  position: relative;
  border-bottom: 1px solid #2b2b2b;
  margin:0;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    margin-bottom: 80px;
  }
`
const Wrapper = styled.div`
  max-width: 560px;
  border-radius: 4px;
  padding: 20px;
  margin: 0px;
  z-index:999;
  td {
    padding: 0;
  }
  @media (min-width: 768px) {
    min-width: 600px;
  }
  @media (min-width: 1024px) {
    min-width: 900px;
    margin: 60px auto;
  }
`
const Outline = styled.div`
  border: 1px solid #06aad4;
  border-radius: 2px;
  padding: 1px;
`
const Inline = styled.div`
  border: 1px solid #06aad4;
  padding: 30px;
  @media (min-width: 420px) {
    padding: 60px 40px;
  }
  @media (min-width: 768px) {
    border: 1px solid #06aad4;
    padding: 80px;
  }
  @media (min-width: 1024px) {
    padding: 120px;
	}
`

const About = ({ title, summary }) => {
  return (
    <Section>
      <Wrapper>
        <Outline>
          <Inline>
            <h2>{ RichText.asText(title) }</h2>
            { RichText.render(summary) }
          </Inline>
        </Outline>
      </Wrapper>
    </Section>
  )
}

export default About
